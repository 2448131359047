input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  outline: none !important;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  background: #fbfbfb;
}

input[type="checkbox"]::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: scale(0) translate(-50%, -50%);
  line-height: 1;
}

input[type="checkbox"]:hover {
  border-color: rgba(170, 170, 170, 0.5);
}

input[type="checkbox"]:checked {
  background-color: #4b77e6;
  border-color: rgba(255, 255, 255, 0.3);
  color: white;
}

input[type="checkbox"]:checked::before {
  border-radius: 2px;
  transform: scale(1) translate(-50%, -50%);
}

input[type="checkbox"] {
  background-color: #cfcfcf;
}

.radio-button {
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  line-height: 18px;
}

/* 2번 그룹 커스텀 라디오 버튼, 외부 원 (border) */
.radio-buttons .radio-button .custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #111111;
  cursor: pointer;
}

/* 2번 그룹 라디오 버튼 선택 시 배경 색상 변경, 외부 원 (border) */
.radio-buttons .radio-button input[type="radio"]:checked ~ .custom-radio {
  border: 1px solid #111111;
}

/* 2번 그룹 라디오 버튼, 내부 원 */
.radio-buttons .custom-radio:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* 2번 그룹 라디오 버튼 선택 시 색상 변경, 내부 원 */
.radio-buttons .radio-button input[type="radio"]:checked ~ .custom-radio:after {
  background-color: #111111;
}

#filter-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#filter-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#filter-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#filter-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
