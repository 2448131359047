* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 20px;
}

li {
  list-style: none;
}

body {
  font-family: "PretendardVariable", sans-serif;
  color: #111111;
  line-height: normal;
}

input::placeholder {
  color: #aeaeae;
}

textarea::placeholder {
  color: #aeaeae;
}

/* 기존 라디오 버튼 삭제 */
input[type="radio"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Edge 브라우저의 input password eye 기능 제거 */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input[type="text"]:disabled {
  background: none;
}

input[type="password"]:disabled {
  background: none;
}

textarea:disabled {
  background: none;
}

textarea::placeholder {
  color: #aeaeae;
}

/* 커스텀 그래프 tooltip 기능 적용을 위한 직접 접근하여 css 제어 */
.DetailCapacityGraph .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: none;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
