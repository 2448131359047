.terms-area {
  padding-top: 120px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
}

.terms-body {
  width: 1000px;
  margin-right: 50px;
  margin-left: 50px;
  padding: 10px;
  overflow: auto;
  white-space: pre-wrap;
}

@media screen and (max-width: 720px) {
  .terms-body {
    margin-right: 2.5%;
    margin-left: 2.5%;
  }
}

.privacy-title {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 50px;
}

.privacy-sub-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 2px;
}

.privacy-content {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 25px;
}
